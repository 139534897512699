import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";

import CaseList from "../sections/cms-case-study/CaseList";
// import CaseList from "../sections/case-study/CaseList1";
import CTA from "../sections/cms-case-study/CTA";

const CaseStudy = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>Our Case Studies</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Section className="pb-0 ">
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="6">
                <Title variant="hero" className="pt-5">
                  Case Studies
                </Title>
                <Text>
                  Take a look at some of the recent projects that we have
                  undertaken for our customers.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <CaseList />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudy;
