import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";
import { Section } from "../../components/Core";
import PostCard from "../../components/PostCard";

type Frontmatter = {
  frontmatter: {
    slug: string;
    client: {
      overviewTextPara1: string;
    };
    hero: {
      title: string;
      subtitle: string;
      quote: string;
      listImage: {
        childImageSharp: {
          fluid: {
            src: string | undefined;
          };
        };
      };
      brandImage: {
        childImageSharp: {
          fluid: {
            src: string | undefined;
          };
        };
      };
    };
  };
};

const CaseList = () => {
  const data = useStaticQuery(graphql`
    query CaseStudiesList {
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "case-study" } } }
      ) {
        nodes {
          frontmatter {
            slug
            client {
              overviewTextPara1
            }
            hero {
              title
              subtitle
              listImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              brandImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const result = data.allMarkdownRemark.nodes;

  return (
    <>
      {/* <!-- Feature section --> */}
      <Section>
        <Container>
          <Row className="d-flex flex-wrap align-items-stretch justify-content-start">
            {result.map((node: Frontmatter) => {
              const { hero, slug, client } = node.frontmatter;
              return (
                <Col lg="4" md="6" className="mb-5 ">
                  <PostCard
                    key={slug}
                    img={hero.listImage.childImageSharp.fluid.src}
                    imgBrand={hero.brandImage.childImageSharp.fluid.src}
                    title={hero.title}
                    subTitle={hero.subtitle}
                    to={`/case-study/${slug}`}
                  >
                    {client.overviewTextPara1}
                  </PostCard>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default CaseList;
